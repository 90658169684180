<template>
  <proposal-gallery></proposal-gallery>
</template>

<script>
import ProposalGallery from '@/views/proposals/ProposalGallery.vue'

export default {
  components: {
    ProposalGallery,
  },
}
</script>
